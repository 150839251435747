import { RegisterApplicationConfig } from 'single-spa'

export const recordApplication: RegisterApplicationConfig[] = [
  {
    name: '@ubits-mf/lxp-notifications',
    app: () => System.import('@ubits-mf/lxp-notifications'),
    activeWhen: [''],
  },
  {
    name: '@ubits-mf/lxp-preference',
    app: () => System.import('@ubits-mf/lxp-preference'),
    activeWhen: [''],
  },
  {
    name: '@ubits-mf/lxp-header',
    app: () => System.import('@ubits-mf/lxp-header'),
    activeWhen: location => !['/login', '/withoutheader'].includes(location.pathname),
  },
  {
    name: '@ubits-mf/lxp-home',
    app: () => System.import('@ubits-mf/lxp-home'),
    activeWhen: ['/home', '/lms'],
  },
  {
    name: '@ubits-mf/lxp-catalog',
    app: () => System.import('@ubits-mf/lxp-catalog'),
    activeWhen: ['/catalog', '/search'],
  },
  {
    name: '@ubits-mf/lxp-profile',
    app: () => System.import('@ubits-mf/lxp-profile'),
    activeWhen: ['/profile', '/portal'],
  },
  {
    name: '@ubits-mf/lxp-admin-hr',
    app: () => System.import('@ubits-mf/lxp-admin-hr'),
    activeWhen: ['/admin-hr'],
  },
  {
    name: '@ubits-mf/lxp-ulms',
    app: () => System.import('@ubits-mf/lxp-ulms'),
    activeWhen: ['/ulms'],
  },
  {
    name: '@ubits-mf/lxp-side-menu',
    app: () => System.import('@ubits-mf/lxp-side-menu'),
    activeWhen: ['/ulms', '/creator', '/talent/360', '/talent/goals', '/admin-hr', '/surveys'],
  },
  {
    name: '@ubits-mf/lxp-playlist',
    app: () => System.import('@ubits-mf/lxp-playlist'),
    activeWhen: [''],
  },
  {
    name: '@ubits-mf/talent-360',
    app: () => System.import('@ubits-mf/talent-360'),
    activeWhen: ['/talent/360'],
  },
  {
    name: '@ubits-mf/lms-creator',
    app: () => System.import('@ubits-mf/lms-creator'),
    activeWhen: ['/creator'],
  },
  {
    name: '@ubits-mf/lms-learner',
    app: () => System.import('@ubits-mf/lms-learner'),
    activeWhen: ['/learner', '/university'],
  },
  {
    name: '@ubits-mf/goals',
    app: () => System.import('@ubits-mf/goals'),
    activeWhen: ['/talent/goals'],
  },
  {
    name: '@ubits-mf/surveys',
    app: () => System.import('@ubits-mf/surveys'),
    activeWhen: ['/surveys'],
  },
]
